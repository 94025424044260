import React from "react";
import "./Styles/style.scss";

//sections:
import ArticleContainer from "./Sections/ArticleContainer";

const Blog = ({ articles }) => (
    <div className="blog">
        {articles.map((e, index) => (
            <ArticleContainer {...e} key={index} />
        ))}
    </div>
);

export default Blog;
