import React from 'react';

const Footer = () => {
    return (
        <div className="footer pt-2 pt-md-4 pb-1">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4">
                        <h4 className="footer__sliderHead">
                            <i className="fas fa-caret-right d-md-none rotate"></i>
                            <span className="pl-2 pl-md-0">Szkoły ponadgimnazjalne</span>
                        </h4>
                        <ul className="footer__sliderBody list-unstyled d-md-block">
                            <li>
                                <a href="http://matura.perspektywy.pl">Serwis maturzystów</a>
                            </li>
                            <li>
                                <a href="http://licea.perspektywy.pl">Licea w Polsce</a>
                            </li>
                            <li>
                                <a href="http://technika.perspektywy.pl">Technika w Polsce</a>
                            </li>
                            <li>
                                <a href="http://ib.perspektywy.pl">Matura międzynarodowa</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <h4 className="footer__sliderHead">
                            <i className="fas fa-caret-right d-md-none rotate"></i>
                            <span className="pl-2 pl-md-0">Studia w Polsce i za granicą</span>
                        </h4>
                        <ul className="footer__sliderBody list-unstyled d-md-block">
                            <li>
                                <a href="http://podyplomowe.perspektywy.pl">Studia podyplomowe</a>
                            </li>
                            <li>
                                <a href="http://doktoranckie.perspektywy.pl">Studia doktoranckie</a>
                            </li>
                            <li>
                                <a href="http://mba.perspektywy.pl">Studia MBA</a>
                            </li>
                            <li>
                                <a href="http://niepubliczne.perspektywy.pl">Uczelnie niepubliczne</a>
                            </li>
                            <li>
                                <a href="http://pwsz.perspektywy.pl">Państwowe Wyższe Szkoły Zawodowe</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <h4 className="footer__sliderHead">
                            <i className="fas fa-caret-right d-md-none rotate"></i>
                            <span className="pl-2 pl-md-0">Rankingi</span>
                        </h4>
                        <ul className="footer__sliderBody list-unstyled d-md-block">
                            <li>
                                <a href="http://engineering.perspektywy.pl/">
                                    <span className="menu-title">Ranking Studiów Inżynierskich 2019</span>
                                </a>
                            </li>
                            <li>
                                <a href="http://ranking.perspektywy.pl">Ranking Szkół Wyższych 2019</a>
                            </li>
                            <li>
                                <a href="http://mba.perspektywy.pl">Ranking MBA Perspektywy 2018</a>
                            </li>
                            <li>
                                <a href="http://licea.perspektywy.pl">Ranking Liceów 2020</a>
                            </li>
                            <li>
                                <a href="http://rankingstem.perspektywy.pl">Ranking Liceów STEM 2016</a>
                            </li>
                            <li>
                                <a href="http://technika.perspektywy.pl">Ranking Techników 2020</a>
                            </li>
                            <li>
                                <a href="http://rankingwarszawski.perspektywy.pl">Ranking Szkół Warszawskich 2016</a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <h4 className="footer__sliderHead">
                            <i className="fas fa-caret-right d-md-none rotate"></i>
                            <span className="pl-2 pl-md-0">Perspektywy Press</span>
                        </h4>
                        <ul className="footer__sliderBody list-unstyled d-md-block">
                            <li>
                                <a href="http://www.salonmaturzystow.pl/" target="_blank" rel="noopener noreferrer">
                                    Salon Maturzystów PERSPEKTYWY
                                </a>
                            </li>
                            <li>
                                <a href="http://www.salon.perspektywy.pl/" target="_blank" rel="noopener noreferrer">
                                    Międzynarodowy Salon Edukacyjny PERSPEKTYWY
                                </a>
                            </li>
                            <li>
                                <a href="http://www.perspektywy.pl/reklama" target="_blank" rel="noopener noreferrer">
                                    Oferta Reklamowa
                                </a>
                            </li>
                            <li>
                                <a href="http://prenumerata.perspektywy.pl" target="_blank" rel="noopener noreferrer">
                                    Prenumerata
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <h4 className="footer__sliderHead">
                            <i className="fas fa-caret-right d-md-none rotate"></i>
                            <span className="pl-2 pl-md-0">Fundacja Edukacyjna „Perspektywy”</span>
                        </h4>
                        <ul className="footer__sliderBody list-unstyled  d-md-block">
                            <li>
                                <a href="http://womenintechsummit.pl" target="_blank" rel="noopener noreferrer">
                                    Women in Tech Summit 2019
                                </a>
                            </li>
                            <li>
                                <a href="http://www.dziewczynynapolitechniki.pl/" target="_blank" rel="noopener noreferrer">
                                    Dziewczyny na politechniki!
                                </a>
                            </li>
                            <li>
                                <a href="http://www.info.studyinpoland.pl/" target="_blank" rel="noopener noreferrer">
                                    Study in Poland – serwis dla uczelni
                                </a>
                            </li>
                            <li>
                                <a href="http://www.perspektywy.org/elsevier" target="_blank" rel="noopener noreferrer">
                                    Elsevier-Perspektywy Young Researcher Award
                                </a>
                            </li>
                            <li>
                                <a href="http://www.studyinpoland.pl/interstudent" target="_blank" rel="noopener noreferrer">
                                    Interstudent
                                </a>
                            </li>
                            <li>
                                <a href="http://www.perspektywy.org/" target="_blank" rel="noopener noreferrer">
                                    Strona Fundacji
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <h4 className="footer__sliderHead">
                            <i className="fas fa-caret-right d-md-none rotate"></i>
                            <span className="pl-2 pl-md-0">In English</span>
                        </h4>
                        <ul className="footer__sliderBody list-unstyled d-md-block">
                            <li>
                                <a href="http://www.studyinpoland.pl/" target="_blank" rel="noopener noreferrer">
                                    Study in Poland
                                </a>
                            </li>
                            <li>
                                <a href="http://www.studyinwarsaw.pl/" target="_blank" rel="noopener noreferrer">
                                    Study in Warsaw
                                </a>
                            </li>
                            <li>
                                <a href="http://www.perspectives.pl/" target="_blank" rel="noopener noreferrer">
                                    Poland International Education Fair PERSPEKTYWY
                                </a>
                            </li>
                            <li>
                                <a href="http://www.perspektywy.org/" target="_blank" rel="noopener noreferrer">
                                    Perspektywy Education Foundation
                                </a>
                            </li>
                            <li>
                                <a href="http://www.ireg-observatory.org/" target="_blank" rel="noopener noreferrer">
                                    IREG Observatory on Academic Ranking
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
