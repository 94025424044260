import React from "react";
import "./Styles/appFooter.scss";

// core components
import Footer from "./Sections/Footer";
import Copyright from "./Sections/Copyright";

function AppFooter() {
    return (
        <footer className="AppFooter mt-3">
            <Footer />
            <Copyright />
        </footer>
    );
}

export default AppFooter;
