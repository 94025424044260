import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Styles/style.scss";

// import ShareButton from "../../components/ShareButton/ShareButton";
import Loader from "../../components/Loader/Loader";

//sections
import ArticleImage from "./Sections/ArticleImage";

const Article = ({ match }) => {
    const slug = match.params.slug;
    const [articleData, setArticleData] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    function createMarkup(inner) {
        return { __html: inner };
    }

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_API_URL + `/v1/article/${slug}`)
            .then(function(response) {
                setArticleData(response.data);
                setIsLoading(false);
            })
            .catch(function(error) {
                setIsError(true);
                setErrorMessage(error.response.data.message);
                setIsLoading(false);
            });
    }, [slug]);

    if (isError === true) {
        return (
            <div className="alert alert-danger my-3" role="alert">
                {errorMessage}
            </div>
        );
    }
    return (
        <div className="post">
            {isLoading ? (
                <Loader size="5" />
            ) : (
                <>
                    <ArticleImage {...articleData} />
                    {/* <ShareButton /> */}
                    <div className="post__content" dangerouslySetInnerHTML={createMarkup(articleData.content)} />
                    {/* <ShareButton justify="justify-content-center" /> */}
                </>
            )}
        </div>
    );
};

export default Article;
