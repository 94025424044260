import React from "react";

const ArticleImage = ({ title, img }) => (
    <div className="article__image">
        <h2 className="article__title text-uppercase p-1">{title}</h2>
        {img && <img className="img-fluid w-100" src={img} alt={title} />}
    </div>
);

export default ArticleImage;
