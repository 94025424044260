import React from 'react';

const Copyright = () => {
    return (
        <div className="copyrights py-1 px-0">
            <div className="container py-1">
                <div className="text-center">
                    <h6 className="copyrights__logo pr-1 mb-1">© 2020 Perspektywy.pl</h6>
                    <ul className="list-unstyled d-flex justify-content-center align-items-end px-0 m-0">
                        <li className="pr-1">
                            <a href="http://www.perspektywy.pl/portal/index.php?option=com_content&amp;view=article&amp;id=28&amp;Itemid=175">O nas</a>
                        </li>
                        <li className="pr-1">
                            <a href="http://www.perspektywy.pl/portal/index.php?option=com_content&amp;view=category&amp;layout=blog&amp;id=13&amp;Itemid=124">Patronaty</a>
                        </li>
                        <li className="pr-1">
                            <a href="http://www.perspektywy.pl/portal/index.php?option=com_content&amp;view=article&amp;id=16&amp;Itemid=123">Polityka Prywatności</a>
                        </li>
                        <li className="pr-1">
                            <a href="http://www.perspektywy.pl/znakjakosci">Znak jakości</a>
                        </li>
                        <li className="pr-1">
                            <a href="http://www.perspektywy.pl/reklama">Reklama</a>
                        </li>
                        <li className="pr-1">
                            <a href="http://www.perspektywy.pl/portal/index.php?option=com_content&amp;view=article&amp;id=23&amp;Itemid=173">Kontakt</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Copyright;
