import React from "react";
import { Link } from "react-router-dom";

const ArticleContainer = ({ slug, title, img, lead, tag, readmore }) => {
    function createMarkup(inner) {
        return { __html: inner };
    }

    return (
        <article>
            <div className="article__image">
                <h2 className="article__title text-uppercase p-1">
                    <Link to={`/article/${slug}`}>{title}</Link>
                </h2>

                <Link to={`/article/${slug}`}>
                    <>
                        {img ? (
                            <img
                                className="img-fluid img--zoom"
                                src={img}
                                alt={title}
                            />
                        ) : (
                            <img
                                className="img-fluid"
                                src="img/error-img.png"
                                alt={title}
                            />
                        )}
                    </>
                </Link>
            </div>
            <div className="article__content">
                <h2 className="article__title mb-3">
                    <Link to={`/article/${slug}`}>{title}</Link>
                </h2>

                <div className="article__text">
                    <div dangerouslySetInnerHTML={createMarkup(lead)} />
                </div>

                {tag && (
                    <div className="article__badge">
                        <ul className="list-unstyled d-flex mb-0">
                            <li className="mr-1">
                                <span className="badge badge-primary">
                                    {tag}
                                </span>
                            </li>
                        </ul>
                    </div>
                )}

                <p className="article__readmore text-right mb-0">
                    <Link to={`/article/${slug}`} className="f-80">
                        {readmore || "Czytaj więcej..."}
                    </Link>
                </p>
            </div>
        </article>
    );
};

export default ArticleContainer;
