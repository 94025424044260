import React from "react";

const Accordion = ({ items }) => {
    return (
        <div className="aside__accordion accordion" id="accordionAside">
            {Object.entries(items).map(([key, value]) => (
                <div className="card" key={key}>
                    <div className="card-header p-0" id={"heading-" + value.id}>
                        <h4 className="accordion__header p-3 mb-0" type="button" data-toggle="collapse" data-target={"#collapse-" + value.id} aria-controls={"collapse-" + value.id}>
                            {value.name}
                        </h4>
                    </div>

                    <div id={"collapse-" + value.id} className="collapse" aria-labelledby={"heading-" + value.id} data-parent="#accordionAside">
                        <div className="card-body">
                            {Object.entries(value.universities).map(([key, value, index]) => (
                                <div key={value[0].name}>
                                    {key && <p className="listItem__heading pl-2 mb-1">{key}</p>}
                                    <ul className={"accordion__list pl-2" + (key && " mb-4")}>
                                        {value.map((e, index) => (
                                            <li className="accordion__listItem mb-2" key={index}>
                                                {e.link ? (
                                                    <a href={e.link} target="_blank" rel="noopener noreferrer">
                                                        <p className="text-primary mb-0">{e.name}</p>
                                                        {e.text && <p className="small text-muted mb-0">{e.text}</p>}
                                                    </a>
                                                ) : (
                                                    <div>
                                                        <p className="text-primary mb-0">{e.name}</p>
                                                        {e.text && <p className="small text-muted mb-0">{e.text}</p>}
                                                    </div>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Accordion;
